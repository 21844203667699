/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import "~ngx-popperjs/css/theme-white.css";

/* Success styling */

.mat-button.mat-success,
.mat-stroked-button.mat-success {
    color: #38C172 !important;
}

.mat-button.mat-success:hover,
.mat-stroked-button.mat-success:hover {
    background-color: #f0fff3 !important;
}

.mat-raised-button.mat-success,
.mat-flat-button.mat-success,
.mat-fab.mat-success,
.mat-mini-fab.mat-success {
    color: #f0fff3 !important;
    background-color: #38C172 !important;
}

.mat-icon-button.mat-success {
    color: #38C172 !important;
}


.mat-list-base .mat-list-item .mat-list-item-content {
    width: 100%;
    justify-content: space-between;
}

.custom-dialog-container .mat-dialog-container{
    padding: 0 !important;
}

.new-status {
    background-color: #36BEFF !important;
}
